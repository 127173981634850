import { Fragment, useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import Classes from '../customers/css/customer.module.css';
import axiosInstance from '../../../utility/axios-instance';
import NewCustomerModal from '../createOrders/aside/newCustomerModal';
import Button from '@mui/material/Button';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import ConfirmDialog from './confirmDialog';
import { services } from '../../../utility/checkRoleBasedPermission';
import Permission from '../../auth/permissions';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import Snackbar from '@mui/material/Snackbar';
import FormDialog from '../users/Components/formDialog';
import Table from '../../../components/table/table';
import { setAffiliates } from '../../../store/affiliates';
import { useSelector } from 'react-redux';
import { Switch } from "@mui/material";

const Affiliate = (props) => {
    const [error, setError] = useState(null);
    const [gstNO, setGstNO] = useState();
    const [showModal, setShowModal] = useState(false);
    const [num,setNum] = useState('');
    const [errorMessage,setErrorMessage] = useState('');
    const [displayName, setDisplayName] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [businessName, setBusinessName] = useState('');
    const [selectedState,setSelectedState] = useState('');
    const [city,setCity] = useState('');
    const [area,setArea] = useState('');
    const [snackBar, setSnackBar] = useState({ display: false, type: "success", message: "default message!" })

    const [confirmDialog,setConfirmDialog] = useState(false);
    const [openVerifyDialog, setOpenVerifyDialog] = useState(false);
    const [customer,setCustomer] = useState('');
    const [Id,setId] = useState('');
    const [reRender, setReRender] = useState(false);

    const affiliates = useSelector((state) => state.affiliates);

    const showConfirmDialog = (customer) => {
        setConfirmDialog(true);
        setCustomer(customer);
    }

    const hideConfirmDialog = () => {
        setConfirmDialog(false);
    }

    const getModal = () => {
        setShowModal(true);
    };

    const hideModal = () => {
        setNum('');
        setDisplayName('');
        setFirstName('');
        setLastName('');
        setBusinessName('');
        setGstNO('');
        setErrorMessage('');
        setShowModal(false);
        setSelectedState('');
        setCity('');
        setArea('');
    };

    const columns = [
        {
            field: 'displayName',
            headerName: 'Name',
            flex: 1.6,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => (
                <div style={{textTransform:'capitalize'}}>{params?.row?.displayName}</div>
            )
        },
        {
            field: 'phone',
            headerName: 'Phone',
            flex: 1,
            headerClassName: 'super-app-theme--header'
        },
        {
            field: 'typeOfRole',
            headerName: 'Role',
            flex: 1,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => (
                <div>{params?.row?.typeOfRole?.toTitleCase()}</div>
            )
        },
        {
            field: 'customerCode',
            headerName: 'Code',
            flex: 1,
            headerClassName: 'super-app-theme--header'
        },
        {
            field: 'salesUser',
            headerName: 'Sales User',
            flex: 1.1,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => (
                <div>{params?.row?.salesUser ? params?.row?.salesUser?.name :'N/A'}</div>
            )
        },
        {
            field: 'preSalesUser',
            headerName: 'Pre Sales User',
            flex: 1.1,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => (
                <div>{params?.row?.preSalesUser ? params?.row?.preSalesUser?.name :'N/A'}</div>
            )
        },
        {
            field: 'createdAt',
            headerName: 'Created At',
            flex: 1.7,
            headerClassName: 'super-app-theme--header'
        },
        {
            field: 'isVerified',
            headerName: 'Verified',
            flex: 0.5,
            headerClassName: 'super-app-theme--header',
            filterable: false,
            sortable: false,
            align: 'center',
            renderCell: (params) => (
                <div>{params?.row?.isVerified ? 
                    <VerifiedUserIcon 
                        style={{color:'green'}}
                    /> : 
                    <Permission service={services?.affiliates} permission={"create"}>
                        <Button
                            variant="contained"
                            color="primary"
                            sx={{ textTransform:'capitalize' }}
                            style={{ padding:'2px 10px',fontSize:14 }}
                            onClick={(event) => handleVerifyDialog(event,params?.row?._id)}
                        >
                            Verify
                        </Button>
                    </Permission>
                }</div>
            )
        },
        {
            field: 'active',
            headerName: 'Active',
            headerClassName: 'super-app-theme--header',
            headerAlign: 'center',
            align: 'center',
            filterable: false,
            sortable: false,
            renderCell: (params) => (
                <Permission service={services?.affiliates} permission={"delete"}>
                    <Switch
                        name="active"
                        checked={params?.row?.active}
                        onClick={() => showConfirmDialog(params?.row)}
                        id="flexSwitchCheckChecked"
                        inputProps={{ 'aria-label': 'controlled' }}
                    />
                </Permission>
            ),
        },
    ];

    const handleSnackBarClose = () => {
        setSnackBar((prevSnackBar) => {
            return { ...prevSnackBar, display: false }
        });
    };

    const handleVerifyDialog = (event,id) => {
		event.preventDefault()
		setOpenVerifyDialog(true);
        setId(id);
	};

    
	const handleVerify = async () => {
        await axiosInstance
            .put(`/user/admin/affiliateCustomer/${Id}`, {
                isVerified: true
            })
            .then((res) => {
                const successMessage = "Customer Verified Successfully!"
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: successMessage, type: "success" }
                });
                setReRender(!reRender);
            })
            .catch((error) => {
                console.log(error)
                const errorMessage = error?.response?.data?.message
                setSnackBar((prevSnackBar) => {
                    return { ...prevSnackBar, display: true, message: errorMessage, type: "error" }
                });
                setReRender(!reRender);
            })
        setOpenVerifyDialog(false)
	};

	const handleCloseVerifyDialog = () => {
		setOpenVerifyDialog(false)
	}

	return (
		<Fragment>
			<div  id="color">
                <FormDialog 
                    open={openVerifyDialog} 
                    handleClose={handleCloseVerifyDialog} 
                    handleSubmit={handleVerify} 
                    dialogTitle={`Verify Affiliate`} 
                    dialogContent={"Are You Sure You Want To Verify Affiliate?"} 
                />
                <div 
                    className="alert alert-primary p-2" 
                    data-file-upload-alert role="alert" 
                    style={{ "display": "none" }}
                    id='fileDownloading'
                >
                    Sheet is exporting...
                </div>

                <div className="d-flex" style={{justifyContent:'flex-end',marginTop:-25,marginBottom:5}}>
                    <Button  
                        startIcon={<PersonAddIcon />} 
                        variant="contained"
                        color="primary"
                        sx={{
                            fontSize:'14px',
                            textTransform:'capitalize',
                            "& .MuiButton-startIcon>*:nth-of-type(1)": {fontSize:'17px'}
                        }}
                        onClick={getModal}
                    >
                        Create
                    </Button>
                </div>

				{error ? (
					<Alert variant="danger" onClose={() => setError(false)} dismissible>
                        <Alert.Heading>{error}</Alert.Heading>
                        <p>Try reloading!</p>
                    </Alert>
                ) : null}

                <div className={[Classes.Container,Classes.MuiTable].join(' ')} >
                    <Table
                        columns={columns}
                        dataPosition={"affiliateData"}
                        endpoint={`/others/getAffiliate`}
                        rowId={'_id'}
                        checkBoxSelection={false}
                        version="version-1"
                        reRender={reRender}
                        service={'affiliates'}
                        tabName={'Affiliate'}
                        setData={setAffiliates}
                        data={affiliates}
                        createdByFilter={true}
                    />
                </div>
            </div>

            <div className={showModal ? Classes.UpdateSalesDialog : undefined}>
                <NewCustomerModal
                    onShowModal={showModal}
                    setModal={setShowModal}
                    onHideModal={hideModal}
                    num={num}
                    setNum={setNum}
                    gstNO={gstNO}
                    setGstNO={setGstNO}
                    errorMessage={errorMessage}
                    setErrorMessage={setErrorMessage}
                    displayName={displayName}
                    setDisplayName={setDisplayName}
                    firstName={firstName}
                    setFirstName={setFirstName}
                    lastName={lastName}
                    setLastName={setLastName}
                    businessName={businessName}
                    setBusinessName={setBusinessName}
                    popUpType='customerDashboard'
                    endpoint={`/user/admin/affiliates`}
                    setSelectedState={setSelectedState}
                    selectedState={selectedState}
                    setCity={setCity}
                    city={city}
                    setArea={setArea}
                    area={area}
                    setReRender={setReRender}
                    reRender={reRender}
                />
            </div>

            <div className={confirmDialog ? Classes.UpdateSalesDialog : undefined}>
                <ConfirmDialog
                    confirmDialog={confirmDialog}
                    customer={customer}
                    hideConfirmDialog={hideConfirmDialog}
                    setReRender={setReRender}
                    reRender={reRender}
                />
            </div>

            <Snackbar open={snackBar?.display} autoHideDuration={3000} onClose={handleSnackBarClose}>
                <Alert onClose={handleSnackBarClose} severity={snackBar?.type} sx={{ width: '100%' }}>
                    {snackBar?.message}
                </Alert>
            </Snackbar>
        </Fragment>
    );
};

export default Affiliate;
