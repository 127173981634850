import { useState, Fragment } from "react";
import { Button, Backdrop } from "@mui/material";
import { Close } from "@mui/icons-material";
import Table from '../../../components/table/table';
import moment from "moment";
import { set as setUserActivities } from '../../../store/userActivitiesSlice';
import { useSelector } from "react-redux";
import VisualizeJSONData from "../../../components/visualizeJSONData";

const UserActivities = (props) => {
  const data = useSelector(state => state.userActivities);

  const [payload, setPayload] = useState(null);
  const [previousData, setPreviousData] = useState(null);
  
  const columns = [
    {
      field: "username",
      headerName: "USERNAME",
      flex: 1.3,
      headerClassName: "super-app-theme--header",
      sortable: true,
      filterable: true,
    },
    {
      field: "name",
      headerName: "NAME",
      flex: 1.3,
      headerClassName: "super-app-theme--header",
      sortable: true,
      filterable: true,
    },
    {
      field: "type",
      headerName: "SERVICE",
      flex: 1.3,
      headerClassName: "super-app-theme--header",
      sortable: true,
      filterable: true,
    },
    {
      field: "operation",
      headerName: "OPERATION",
      flex: 1.3,
      headerClassName: "super-app-theme--header",
      sortable: true,
      filterable: true,
      enumValues: ['get', 'create', 'update', 'delete']
    },
    {
      field: "message",
      headerName: "MESSAGE",
      flex: 1.3,
      headerClassName: "super-app-theme--header",
      sortable: false,
      filterable: true,
    },
    {
      field: "status",
      headerName: "STATUS",
      flex: 1.3,
      headerClassName: "super-app-theme--header",
      sortable: false,
      filterable: true,
      enumValues: ['pending', 'successful', 'failed']
    },
    {
      field: "payload",
      headerName: "PAYLOAD",
      flex: 1.3,
      headerClassName: "super-app-theme--header",
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        let data;
        try {
          data = JSON.parse(params.value)
          } catch (e) {}

        return data ? <Button variant="contained" size="small" onClick={() => {
          console.log(data)
          setPayload(data)
        }}>View Payload</Button> : null
      }
    },
    {
      field: "previous_data",
      headerName: "PREVIOUS DATA",
      flex: 1.3,
      headerClassName: "super-app-theme--header",
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        let data;
        try {
          data = JSON.parse(params.value)
          } catch (e) {}

        return data ? <Button variant="contained" size="small" onClick={() => {
          console.log(data)
          setPreviousData(data)
        }}>Previous Data</Button> : null
      }
    },
    {
      field: "created_at",
      headerName: "CREATED AT",
      flex: 1.3,
      headerClassName: "super-app-theme--header",
      sortable: true,
      filterable: false,
      renderCell: (params) => {
        return moment(params.value).toString()
      }
    },
  ];

  return (
    <Fragment>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={!!payload}
        onClick={() => setPayload(null)}
      >
      <div style={{
        backgroundColor: 'white',
        borderRadius: '8px',
        width: '90%',
        maxHeight: '90%',
        overflow: 'auto',
        maxWidth: '600px',
        padding: '1rem',
        paddingTop: '1.125rem',
        position: 'relative'
      }} onClick={(e) => {
        e.stopPropagation()
      }}>
        <div style={{
          color: 'black',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}>
          <h3>Payload</h3>
          <Close style={{
          cursor: 'pointer',
          
        }} onClick={() => setPayload(null)} />
        </div>
        <VisualizeJSONData payload={payload} />
      </div>
      </Backdrop>

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={!!previousData}
        onClick={() => setPreviousData(null)}
      >
        <div style={{
          backgroundColor: 'white',
          borderRadius: '8px',
          width: '90%',
          maxHeight: '90%',
          overflow: 'auto',
          maxWidth: '600px',
          padding: '1rem',
          paddingTop: '1.125rem',
          position: 'relative'
        }} onClick={(e) => {
          e.stopPropagation()
        }}>
          <div style={{
            color: 'black',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}>
            <h3>Previous Data</h3>
            <Close style={{
            cursor: 'pointer',
            
          }} onClick={() => setPreviousData(null)} />
          </div>
          <VisualizeJSONData payload={previousData} />
        </div>
      </Backdrop>

      <Table
        columns={columns}
        dataPosition={"activities"}
        endpoint={`/userActivities`}
        rowId={'id'}
        checkBoxSelection={false}
        tabName={'User Activities'}
        setData={setUserActivities}
        data={data}
      />
    </Fragment>
  );
};

export default UserActivities;
