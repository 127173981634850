import React, { Fragment, useState } from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Table from '../../../components/table/table';
import { Button, Tooltip } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import SyncInventoryPopup from '../inventory/syncPopup';
import InventoryUploadPopup from './inventoryUpload';
import ExportDatas from '../../../components/exportDataDialog';
import MobileResponsive from '../orderAllocation/mobileResponsive';
import Classes from '../customers/css/customer.module.css';
import { services } from '../../../utility/checkRoleBasedPermission';
import Permission from '../../auth/permissions';
import { useSelector } from 'react-redux';
import { setInventoryView } from '../../../store/inventoryView';
import { setInventoryViewBySku } from '../../../store/inventoryViewBySku';
import QrCodesDownloadPopup from './qrCodesDownload';
import StockTransferDialog from './stockTransferDialog';
import SnackBar from '../../../components/SnackBar';
import Spinner from '../../../components/spinner';

const colorizeRowData = (data, condition, physicalParentSku) => (
    <Tooltip 
        title={condition === true 
        ? `Virtual Inventory ${physicalParentSku ? ` || ${physicalParentSku}`:''}` 
        : 'Physical Inventory'} 
        placement="top"
    >
        <div style={{color: condition === true ? "#ff6200" : '#000'}}>{data}</div>
    </Tooltip>);

const skuBinMappingColumns = [
    {
        field: 'skuBinMappingId',
        headerName: 'Bin Mapping Id',
        flex:1,
        headerClassName: 'super-app-theme--header',
        filterable : false,
        renderCell: (params) => colorizeRowData(params?.row?.skuBinMappingId, params?.row?.isVirtual === true, params?.row?.physicalParentSku)
    },
    { 
        field: 'sku', 
        headerName: 'SKU', 
        flex:1.5,
        headerClassName: 'super-app-theme--header',
        renderCell: (params) => colorizeRowData(params?.row?.sku, params?.row?.isVirtual === true, params?.row?.physicalParentSku)
    },
    { 
        field: 'productName', 
        headerName: 'PRODUCT NAME', 
        flex:2,
        headerClassName: 'super-app-theme--header',
        renderCell: (params) => colorizeRowData(params?.row?.productName, params?.row?.isVirtual === true, params?.row?.physicalParentSku)
    },
    {
        field: 'binCode',
        headerName: 'BIN CODE',
        flex:1,
        headerAlign : 'center',
        align : 'center',
        headerClassName: 'super-app-theme--header',
        renderCell: (params) => colorizeRowData(params?.row?.binCode, params?.row?.isVirtual === true, params?.row?.physicalParentSku)
    },
    {
        field: 'binType',
        headerName: 'BIN TYPE',
        flex:0.8,
        headerAlign : 'center',
        align : 'center',
        headerClassName: 'super-app-theme--header',
        renderCell: (params) => colorizeRowData(params?.row?.binType, params?.row?.isVirtual === true, params?.row?.physicalParentSku)
    },
    {
        field: 'totalQty',
        headerName: 'TOTAL QTY',
        type : 'number',
        flex:1,
        headerClassName: 'super-app-theme--header',
        headerAlign : 'center',
        align : 'center',
        renderCell: (params) => colorizeRowData(params?.row?.totalQty, params?.row?.isVirtual === true, params?.row?.physicalParentSku)
    },
    {
        field: 'availableQty',
        headerName: 'AVAILABLE QTY',
        type : 'number',
        flex:1,
        headerClassName: 'super-app-theme--header',
        headerAlign : 'center',
        align : 'center',
        renderCell: (params) => colorizeRowData(params?.row?.availableQty, params?.row?.isVirtual === true, params?.row?.physicalParentSku)
    },
    {
        field: 'committedQty',
        headerName: 'COMMITTED QTY',
        type : 'number',
        flex:1,
        headerClassName: 'super-app-theme--header',
        headerAlign : 'center',
        align : 'center',
        renderCell: (params) => colorizeRowData(params?.row?.committedQty, params?.row?.isVirtual === true, params?.row?.physicalParentSku)
    },
    {
        field: 'pickedQty',
        headerName: 'PICKED QTY',
        type : 'number',
        flex:1,
        headerClassName: 'super-app-theme--header',
        headerAlign : 'center',
        align : 'center',
        sortable : false,
        filterable : false,
        renderCell: (params) => colorizeRowData(params?.row?.pickedQty, params?.row?.isVirtual === true, params?.row?.physicalParentSku)
    },
    {
        field: 'locationCode',
        headerName: 'LOCATION',
        minWidth: 100,
        flex:0.8,
        headerClassName: 'super-app-theme--header',
        valueGetter: (params) =>
        `${params.row.locationCode} ${params?.row?.locationName || ''}`,
        renderCell: (params) => colorizeRowData(params?.row?.locationCode, params?.row?.isVirtual === true, params?.row?.physicalParentSku)
    },
    {
        field: 'physicalParentSku',
        headerName: 'Physical Parent Sku',
        minWidth: 100,
        flex:0.8,
        headerClassName: 'super-app-theme--header',
        renderCell: (params) => colorizeRowData(params?.row?.physicalParentSku, params?.row?.isVirtual === true, params?.row?.physicalParentSku)
    },
    {
        field: 'brandName',
        headerName: 'Brand Name',
        minWidth: 100,
        flex:0.8,
        headerClassName: 'super-app-theme--header',
        filterable : true,
        type: 'enum',
        tableName: 'product_collections',
        columnName: 'brand_name',
        renderCell: (params) => colorizeRowData(params?.row?.brandName, params?.row?.isVirtual === true, params?.row?.physicalParentSku)
    },
    { 
        field: 'createdAt', 
        headerName: 'CREATED AT', 
        flex:1.8,
        headerClassName: 'super-app-theme--header',
        filterable: false,
        renderCell: (params) => colorizeRowData(params?.row?.createdAt, params?.row?.isVirtual === true, params?.row?.physicalParentSku)
    },
    { 
        field: 'updatedAt', 
        headerName: 'UPDATED AT', 
        flex:1.8,
        headerClassName: 'super-app-theme--header',
        filterable: false,
        renderCell: (params) => colorizeRowData(params?.row?.updatedAt, params?.row?.isVirtual === true, params?.row?.physicalParentSku)
    },
];

const skuColumns =  [
    {
        field: 'inventoryId',
        headerName: 'Inventory Id',
        flex:1,
        headerClassName: 'super-app-theme--header',
        filterable: false,
        renderCell: (params) => colorizeRowData(params?.row?.inventoryId, params?.row?.isVirtual === true)
    },
    { 
        field: 'skuCode', 
        headerName: 'SKU', 
        flex:1,
        headerClassName: 'super-app-theme--header',
        renderCell: (params) => colorizeRowData(params?.row?.skuCode, params?.row?.isVirtual === true)
    },
    { 
        field: 'productName', 
        headerName: 'PRODUCT NAME', 
        flex:2,
        headerClassName: 'super-app-theme--header',
        renderCell: (params) => colorizeRowData(params?.row?.productName, params?.row?.isVirtual === true)
    },
    { 
        field: 'binType', 
        headerName: 'BIN TYPE', 
        flex:1,
        headerClassName: 'super-app-theme--header',
        renderCell: (params) => colorizeRowData(params?.row?.binType, params?.row?.isVirtual === true)
    },
    {
        field: 'totalQty',
        headerName: 'TOTAL QTY',
        type : 'number',
        flex:1,
        headerClassName: 'super-app-theme--header',
        headerAlign : 'center',
        align : 'center',
        renderCell: (params) => colorizeRowData(params?.row?.totalQty, params?.row?.isVirtual === true)
    },
    {
        field: 'availableQty',
        headerName: 'AVAILABLE QTY',
        type : 'number',
        flex:1,
        headerClassName: 'super-app-theme--header',
        headerAlign : 'center',
        align : 'center',
        renderCell: (params) => colorizeRowData(params?.row?.availableQty, params?.row?.isVirtual === true)
    },
    {
        field: 'committedQty',
        headerName: 'COMMITTED QTY',
        type : 'number',
        flex:1,
        // filterable : false,
        headerClassName: 'super-app-theme--header',
        headerAlign : 'center',
        align : 'center',
        renderCell: (params) => colorizeRowData(params?.row?.committedQty, params?.row?.isVirtual === true)
    },
    {
        field: 'pickedQty',
        headerName: 'PICKED QTY',
        type : 'number',
        flex:1,
        filterable : false,
        sortable: false,
        headerClassName: 'super-app-theme--header',
        headerAlign : 'center',
        align : 'center',
        renderCell: (params) => colorizeRowData(params?.row?.pickedQty, params?.row?.isVirtual === true)
    },
    {
        field: 'locationCode',
        headerName: 'LOCATION',
        minWidth: 100,
        flex:0.8,
        headerClassName: 'super-app-theme--header',
        valueGetter: (params) =>
        `${params.row.locationCode} ${params?.row?.locationName || ''}`,
        // sortable : false
        renderCell: (params) => colorizeRowData(params?.row?.locationCode, params?.row?.isVirtual === true)
    },
    {
        field: 'brandName',
        headerName: 'Brand Name',
        minWidth: 100,
        flex:0.8,
        headerClassName: 'super-app-theme--header',
        filterable : true,
        type: 'enum',
        tableName: 'product_collections',
        columnName: 'brand_name',
        renderCell: (params) => colorizeRowData(params?.row?.brandName, params?.row?.isVirtual === true)
    },
    { 
        field: 'createdAt', 
        headerName: 'CREATED AT', 
        flex:1.8,
        headerClassName: 'super-app-theme--header',
        filterable: false,
        renderCell: (params) => colorizeRowData(params?.row?.createdAt, params?.row?.isVirtual === true)
    },
    { 
        field: 'updatedAt', 
        headerName: 'UPDATED AT', 
        flex:1.8,
        headerClassName: 'super-app-theme--header',
        filterable: false,
        renderCell: (params) => colorizeRowData(params?.row?.updatedAt, params?.row?.isVirtual === true)
    },
];

const columnVisibilityModelSkuBinMapping = {
    "createdAt":false,
    "updatedAt":false,
    "skuBinMappingId": false,
    "physicalParentSku": false,
    "brandName": false,
}

const columnVisibilityModelInventory = {
    "createdAt":false,
    "updatedAt":false,
    "inventoryId": false,
    "binType": false,
    "brandName": false,
}

const InventoryView = (props) => {
    const [inventoryType,setInventoryType] = useState('inventory-view');
    const [alignment, setAlignment] = useState('bySkuBin');
    const [popUp,setPopUp] = useState(false);
    const [inventoryPopUp,setInventoryPopUp] = useState(false);
    const [open,setOpen] = useState(false);
    const [qrCodesPopUp,setQRCodesPopUp] = useState(false);
    const [stockTransferPopup,setStockTransferPopup] = useState(false);
    const [reRender, setReRender ] = useState(false);
    const [snackBar, setSnackBar] = useState({ display: false, type: "success", message: "Done" })
    const [loading, setLoading] = useState(false);
    const serviceName = services?.inventoryView;

    const inventoryView = useSelector((state) => state.inventoryView);
    const inventoryViewBySku = useSelector((state) => state.inventoryViewBySku);

    const handleExportDialog = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    const handleChange = (event) => {
        if (event.target.value === 'bySkuBin') {
            setInventoryType('inventory-view');
        } else {
            setInventoryType('fetchInventoryBySku');
        }
        setAlignment(event.target.value);
    };

    const showPopUp = () => {
        setPopUp(true);
    }

    const hidePopUp = () => {
        setPopUp(false);
    }

    const showQrCodesPopUp = () => {
        setQRCodesPopUp(true);
    }

    const hideQrCodesPopUp = () => {
        setQRCodesPopUp(false);
    }

    const showInventoryPopUp = () => {
        setInventoryPopUp(true);
    }

    const hideInventoryPopUp = () => {
        setInventoryPopUp(false);
    }

    return (
        <Fragment>
            <div style={{display:'flex',justifyContent:'flex-end',flexWrap:'wrap',marginBottom:15,width:'99%'}} className={Classes.inventoryTabHeader}>
                {process.env.REACT_APP_VINCULUM_CONNECTION === 'true' && (
                    <div style={{marginRight:10}}>
                        <Button onClick={() => showInventoryPopUp()} variant="contained" color="primary" style={{fontSize:12}}>
                            Inventory Upload
                        </Button>
                    </div>
                )}
                <Permission service={serviceName} permission={"update"}>
                    <Button onClick={() => setStockTransferPopup(true)} variant="contained" color="primary" style={{fontSize:12,marginRight:10}}>
                        Virtual Stock Transfer
                    </Button>
                </Permission>

                <Permission service={serviceName} permission={"read"}>
                    <Button onClick={() => showQrCodesPopUp()} variant="contained" color="primary" style={{fontSize:12,marginRight:10}}>
                        Download QR Codes
                    </Button>

                    <div style={{marginRight:10,display:'flex'}}>
                        <Button onClick={() => showPopUp()} startIcon={<RefreshIcon />} variant="contained" color="primary" style={{fontSize:12,marginRight:10}}>
                            Sync Inventory
                        </Button>

                        <Button  
                            variant="contained"
                            color="primary"
                            onClick={handleExportDialog}
                            style={{fontSize:12}}
                        >
                            Export
                        </Button>
                    </div>
                </Permission>
                
                <ToggleButtonGroup
                    color="primary"
                    value={alignment}
                    exclusive
                    onChange={handleChange}
                    aria-label="Platform"
                >
                    <ToggleButton value="bySkuBin" style={{padding:"4px 10px",height:34}}>By Sku Bin</ToggleButton>
                    <ToggleButton value="bySku" style={{padding:"4px 10px",height:34}}>By Sku</ToggleButton>
                </ToggleButtonGroup>
            </div>
            {inventoryType === 'inventory-view' && (
                <div>
                    <div className={Classes.MuiTable}>
                        <Table 
                            columns={skuBinMappingColumns}
                            dataPosition={"fetchedSkuBinMappings"} 
                            endpoint={`/inventory/inventory-view?tab="inventory-view"`} 
                            rowId={'skuBinMappingId'}
                            checkBoxSelection={false}
                            columnVisibilityModel={columnVisibilityModelSkuBinMapping}
                            initialFilterState={{
                                items: [
                                    {
                                        field: 'totalQty', operator: '<>', value: 0
                                    }
                                ]
                            }}
                            service={'inventoryView'}
                            type={inventoryType}
                            tabName={'Inventory View'}
                            setData={setInventoryView}
                            data={inventoryView}
                            reRender={reRender}
                        />
                    </div>
                    <div className={Classes.MobileResponsive}>
                        <MobileResponsive 
                            columns={skuBinMappingColumns} 
                            dataPosition={"fetchedSkuBinMappings"} 
                            endpoint={`/inventory/inventory-view`} 
                            rowId={'skuBinMappingId'}
                            reRender={reRender}
                        />
                    </div>
                </div>
            )}

            {inventoryType === 'fetchInventoryBySku' && (
                <div>
                    <div className={Classes.MuiTable}>
                        <Table 
                            columns={skuColumns} 
                            dataPosition={"fetchedInventories"} 
                            endpoint={`/inventory/fetchInventoryBySku?tab="fetchInventoryBySku"`} 
                            rowId={'inventoryId'}
                            checkBoxSelection={false}
                            columnVisibilityModel={columnVisibilityModelInventory}
                            initialFilterState={{
                                items: [
                                    {
                                        field: 'binType', operator: '=', value: 'Saleable'
                                    }
                                ]
                            }}
                            service={'inventoryView'}
                            type={inventoryType}
                            tabName={'Inventory View'}
                            setData={setInventoryViewBySku}
                            data={inventoryViewBySku}
                            reRender={reRender}
                        />
                    </div>

                    <div className={Classes.MobileResponsive}>
                        <MobileResponsive 
                            columns={skuColumns} 
                            dataPosition={"fetchedInventories"} 
                            endpoint={`/inventory/fetchInventoryBySku`} 
                            rowId={'inventoryId'}
                            reRender={reRender}
                        />
                    </div>
                </div>
            )}

            <SyncInventoryPopup open={popUp} handleClose={hidePopUp} />

            <QrCodesDownloadPopup 
                open={qrCodesPopUp} 
                handleClose={hideQrCodesPopUp} 
                header={'QR Codes Pdf Download'}
                buttonText={'QR Codes'}
                importFile={false}
            />

            <InventoryUploadPopup open={inventoryPopUp} handleClose={hideInventoryPopUp}/>

            <StockTransferDialog 
                open={stockTransferPopup} 
                handleClose={() => setStockTransferPopup(false)}
                reRender={reRender}
                setReRender={setReRender}
                setSnackBar={setSnackBar}
                setLoading={setLoading}
            />

            <ExportDatas 
                open={open}
                handleClose={handleClose}
                endPoint={inventoryType === 'fetchInventoryBySku' ? '/exports/inventoryViewBySku': '/exports/inventoryViewBySkuBin'}
                dataName={`Inventory View ${inventoryType === 'fetchInventoryBySku' ? "By SKU" : "By SKU Bin"}`}
            />

        <SnackBar snackBar={snackBar} setSnackBar={setSnackBar} />
        {loading ? <Spinner /> : null}
        </Fragment>
    )
}
export default InventoryView;