import React, { Fragment, useEffect, useState } from "react";
import Classes from "../css/Modal.module.css";
import axiosInstance from '../../../../utility/axios-instance';
import { TextField, Autocomplete } from '@mui/material';
import Spinner from '../../../../components/spinner';


const ShippingPopUp = (props) => {
    const [states, setStates] = useState([]);
    const [loading, setLoading] = useState(false);

    const {
        shippingModal,
        hideShippingPop,
        showCustomerDetail,
        addressArray,
        setAddressArray,
        addressType,
        num,
        setNum,
        pincode,
        setPincode,
        setSelectedShippingAddress,
        setSelectedBillingAddress
    } = props;

    async function handlePincodeChange(event) {
        const limit = 6;
        const enteredPincode = event.target.value.slice(0, limit)
        setPincode(enteredPincode);
        try {
            const response = await fetch(`https://api.postalpincode.in/pincode/${enteredPincode}`);
            const data = await response.json();

            if (data[0]?.Status === 'Success') {
                const state = document.getElementById('state');
                const extractedState = data[0]?.PostOffice[0]?.State;
                state.value = extractedState;
            }
        } catch (err) {
            console.log(err);
        }
    }

    const handleNumChange = event => {
        const limit = 10;
        setNum(event.target.value.slice(0, limit));
    };


    const handleSubmit = (event) => {
        setLoading(true);
        event.preventDefault();
        hideShippingPop();

        axiosInstance
            .post(`/user/admin/${showCustomerDetail[0]._id}/address`, {
                address1: event.target.address1.value,
                address2: event.target.address2.value,
                city: event.target.city.value,
                province: event.target.state.value,
                zip: event.target.pincode.value,
                country: event.target.country.value,
                firstName: event.target.first.value,
                lastName: event.target.last.value ? event.target.last.value : undefined,
                nick_name: event.target.nick_name.value ?? undefined,
                phone: `+91${event.target.phone.value}`,
                addressType: addressType,
                ...(addressType !== "shipping" && { gstNumber: event?.target?.gst?.value ? event?.target?.gst?.value : undefined }),
            })
            .then((res) => {
                setLoading(false);
                setAddressArray([...addressArray, res.data.data])

                if (res.data.data.addressType === 'shipping') {
                    setSelectedShippingAddress(res?.data?.data)
                } else {
                    setSelectedBillingAddress(res?.data?.data)
                }
            })
            .catch((err) => {
                setLoading(false);
                alert(err?.response?.data?.message || 'Something went wrong');
                console.log(err);
            });
    }

    const handleMinLengthPinCode = (event) => {
        if (event.target.value) {
            const pincode = /^\d{6}$/;
            if (event.target.value.match(pincode)) {
                document.getElementById('pinErrMsg').style.display = 'none';
                document.getElementById('addAddress').disabled = false;
            } else {
                document.getElementById('addAddress').disabled = true;
                document.getElementById('pinErrMsg').style.display = 'block';
            }
        } else {
            document.getElementById('pinErrMsg').style.display = 'none';
            document.getElementById('addAddress').disabled = false;
        }
    }

    const handleMinLengthPhoneNo = (event) => {
        if (event.target.value) {
            const phoneNo = /^\d{10}$/;
            if (event.target.value.match(phoneNo)) {
                document.getElementById('phoneErrMsg').style.display = 'none';
                document.getElementById('addAddress').disabled = false;
            } else {
                document.getElementById('addAddress').disabled = true;
                document.getElementById('phoneErrMsg').style.display = 'block';
            }
        } else {
            document.getElementById('phoneErrMsg').style.display = 'none';
            document.getElementById('addAddress').disabled = false;
        }
    }

    useEffect(() => {
        axiosInstance
            .get('/others/AllStates')
            .then((response) => {
                const states = [];
                for (let stateName of response.data.data) {
                    states.push({ id: stateName?._id, label: stateName?.state })
                }
                setStates(states)
            })
    }, [])

    const handleValidGst = (event) => {
        let regex = new RegExp(/^[0-9]{2}[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9A-Za-z]{1}[zZ]{1}[0-9a-zA-Z]{1}$/);

        if (event.target.value == null) {
            return "false";
        }

        if (regex.test(event.target.value) === true) {
            document.getElementById('addAddress').disabled = false;
            document.getElementById('isValidGst').style.display = 'none';
        } else {
            if (event.target.value === '') {
                document.getElementById('addAddress').disabled = false;
                document.getElementById('isValidGst').style.display = 'none';
            } else {
                document.getElementById('addAddress').disabled = true;
                document.getElementById('isValidGst').style.display = 'block';
            }
        }
    }

    return (
        <Fragment>
            {shippingModal && (
                <div className={Classes.modal} style={{ bottom: '10%', top: '3%' }}>
                    <div className={Classes.BoxContent}>
                        <p>Add New Address</p>
                    </div>

                    <form method="post" onSubmit={handleSubmit} id='formData'>
                        <div className={Classes.AddressPopUp}>
                            <div style={{ marginTop: 10 }}>
                                <TextField
                                    id='country'
                                    name='country'
                                    label='Country / Origin'
                                    value='India'
                                    autoComplete='off'
                                    sx={{ width: '100%', "& label": { top: "0%", fontSize: 14 }, "& .MuiOutlinedInput-input": { padding: '6px 10px' } }}
                                    disabled={true}
                                />
                            </div>

                            <div style={{ display: 'flex', marginTop: 10, justifyContent: 'space-between' }}>
                                <div style={{ width: '48%' }}>
                                    <TextField
                                        required={true}
                                        id='first'
                                        size="small"
                                        name='first'
                                        label='First Name'
                                        autoComplete='off'
                                        placeholder='Enter Your First Name'
                                        sx={{ width: '100%' }}
                                    />
                                </div>

                                <div style={{ width: '48%' }}>
                                    <TextField
                                        id='last'
                                        name='last'
                                        size="small"
                                        label='Last Name'
                                        autoComplete='off'
                                        placeholder='Enter Your Last Name'
                                        sx={{ width: '100%' }}
                                    />
                                </div>
                            </div>

                            <div style={{ display: 'flex', marginTop: 10, justifyContent: 'space-between' }}>
                                <div style={{ width: '48%' }}>
                                    <TextField
                                        required={false}
                                        id='nick_name'
                                        size="small"
                                        name='nick_name'
                                        label='Nick Name'
                                        autoComplete='off'
                                        placeholder='Enter Your Nick Name'
                                        sx={{ width: '100%' }}
                                    />
                                </div>
                            </div>

                            <div style={{ marginTop: '10px' }}>
                                <TextField
                                    required={true}
                                    id='address1'
                                    name='address1'
                                    label='Address 1'
                                    size="small"
                                    autoComplete='off'
                                    placeholder='Enter Your Address'
                                    sx={{ width: '100%' }}
                                />
                            </div>

                            <div style={{ marginTop: '10px' }}>
                                <TextField
                                    id='address2'
                                    name='address2'
                                    label='Address 2'
                                    autoComplete='off'
                                    placeholder='Apartment,Suite,etc'
                                    sx={{ width: '100%' }}
                                />
                            </div>

                            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 10 }}>
                                <div style={{ width: '48%' }}>
                                    <TextField
                                        required={true}
                                        id='city'
                                        name='city'
                                        label='City'
                                        size="small"
                                        autoComplete='off'
                                        placeholder='Enter Your City'
                                        sx={{ width: '100%' }}
                                    />
                                </div>

                                <div style={{ width: '48%' }}>
                                    <TextField
                                        type="number"
                                        required={true}
                                        id='pincode'
                                        size="small"
                                        name='pincode'
                                        label='Pincode'
                                        autoComplete='off'
                                        placeholder='Enter Your Pincode'
                                        maxLength={6}
                                        minLength={6}
                                        inputProps={{ maxLength: 6 }}
                                        sx={{ width: '100%' }}
                                        value={pincode}
                                        onBlur={(e) => handleMinLengthPinCode(e)}
                                        onChange={handlePincodeChange}
                                    />

                                    <div
                                        className={Classes.PhoneErrorMsg}
                                        id='pinErrMsg'
                                        style={{ fontSize: '14px' }}
                                    >
                                        <p>Please enter a valid 6 digit pincode</p>
                                    </div>
                                </div>
                            </div>

                            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 10 }}>
                                <div style={{ width: '48%' }}>
                                    <Autocomplete
                                        // freeSolo
                                        disablePortal
                                        size="small"
                                        id="state"
                                        name='state'
                                        options={states}
                                        ListboxProps={{ sx: { fontSize: 14 } }}
                                        sx={{ width: '100%' }}
                                        // onChange={(event, newValue) => { setSelectedState(newValue.id) }}
                                        renderInput={(params) => <TextField {...params} label="States" required />}
                                    />
                                </div>

                                <div style={{ width: '48%' }}>
                                    <TextField
                                        type="number"
                                        required={true}
                                        id='phone'
                                        name='phone'
                                        label='Phone'
                                        size="small"
                                        autoComplete='off'
                                        placeholder='Enter Your Phone Number'
                                        maxLength={10}
                                        sx={{ width: '100%' }}
                                        value={num}
                                        onBlur={(e) => handleMinLengthPhoneNo(e)}
                                        onChange={(e) => handleNumChange(e)}
                                    />

                                    <div
                                        className={Classes.PhoneErrorMsg}
                                        id='phoneErrMsg'
                                    >
                                        <p>Please enter a valid 10 digit mobile number</p>
                                    </div>
                                </div>
                            </div>
                            {addressType !== 'shipping' && (

                            <div style={{ display: 'flex', marginTop: 10, justifyContent: 'space-between' }}>
                                <div style={{ width: '48%' }}>
                                    <TextField
                                        id='gst'
                                        name='gst'
                                        label='GST Number'
                                        size="small"
                                        autoComplete='off'
                                        placeholder='Enter GST Number'
                                        sx={{ width: '100%' }}
                                        onBlur={handleValidGst}

                                    />
                                    <div
                                        id='isValidGst'
                                        className={Classes.IsValidGst}
                                    >
                                        <p>Please fill valid Gst Number</p>
                                    </div>
                                </div>
                            </div>
                            )}
                        </div>

                        <div className={Classes.DialogBtnBox} style={{ bottom: "10%" }}>
                            <button
                                type="button"
                                className={Classes.UpdateSalesUserOfCustomer}
                                onClick={hideShippingPop}
                            >
                                CANCEL
                            </button>

                            <button
                                id='addAddress'
                                type="submit"
                                className={Classes.UpdateSalesUserOfCustomer}
                            >
                                ADD
                            </button>
                        </div>
                    </form>

                    {loading ? <Spinner /> : null}
                </div>
            )}
        </Fragment>
    );
}


export default ShippingPopUp;